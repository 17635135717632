@import url(https://fonts.googleapis.com/css2?family=Amiri&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Changa:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cairo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Tajawal:wght@400;700;900&display=swap);

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.MuiButtonBase-root{
  font-size: large;
}

#validation-outlined-input {
  font-family: "Tajawal", sans-serif;
  color: #191970;
  font-size:14px;
}

#validation-outlined-input-label {
  font-family: "Tajawal", sans-serif;
  color: #191970;
  font-size:12px;
}

#validation-outlined-input-div {
  color: #191970;
border-width: 5px;
}



#validation-outlined-input .MuiOutlinedInput-notchedOutline{
  font-family: "Tajawal", sans-serif;
  color: #191970;
  font-size:16px;
  border-width: 5px;
}

#combo-box-demo-label{
  font-family: "Tajawal", sans-serif;
  color: #b31d3e;
  font-size:16px;
  font-weight: 700;
}
#TextFieldsearch{
  font-family: "Tajawal", sans-serif;
  color: #191970;
  font-size:16px;
}
#TextFieldsearch-label{
  font-family: "Tajawal", sans-serif;
  color: #b31d3e;
  font-size:16px;
  font-weight: 700;
}
 
#footer {
   
  bottom: 0;
  width: 100%;
}

.MuiInputBase-root{
  direction: rtl;
  text-align: center;
}
#formatted-text-mask-input{
  text-align: right;
    direction: ltr;
}
/* font-family: 'Amiri', serif;
 */

/* font-family: 'Roboto Slab', serif; */
/* font-family: 'Cairo', sans-serif;
 */
/* font-family: 'Changa', sans-serif;
 */
 /* font-family: 'Cairo', sans-serif;
*/
/* font-family: 'Tajawal', sans-serif;
*/
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'SEGA LOGO FONT';
font-style: normal;
font-weight: normal;
src: local('SEGA LOGO FONT'), url(/static/media/SEGA.12a3db8b.woff) format('woff');
}

h1 {
  font-family: 'SEGA LOGO FONT';
  font-weight: 200;
  color: #0ccac4;
}
#HideBld{ display: none;}
.pagination{
  direction: ltr;
}

.selectsp{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
 
}
.selectbld{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
 
}
.selectwly{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
 
}

.input-lg{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
}
#applink{
  text-decoration: none;
}

.wrapapplink{
  margin: 10px;
}
html,
body {
    height: 100%;
}

    .container_loading {
      height: 100vh;
      display: flex;
    justify-content: center;
    align-items: center;
  }
  .txtspin {
  font-weight: bold;
  margin-top: 5px;
    
}
.spinsize{
  width: 3rem;
  height: 3rem;
}

   
  
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'SEGA LOGO FONT';
font-style: normal;
font-weight: normal;
src: local('SEGA LOGO FONT'), url(/static/media/SEGA.12a3db8b.woff) format('woff');
}

h1 {
  font-family: 'SEGA LOGO FONT';
  font-weight: 200;
  color: #0ccac4;
}
#HideBld{ display: none;}
.pagination{
  direction: ltr;
}

.selectsp{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
 
}
.selectbld{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
 
}
.selectwly{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
 
}

.input-lg{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
}
#applink{
  text-decoration: none;
}

.wrapapplink{
  margin: 10px;
}
html,
body {
    height: 100%;
}

    .container_loading {
      height: 100vh;
      display: flex;
    justify-content: center;
    align-items: center;
  }
  .txtspin {
  font-weight: bold;
  margin-top: 5px;
    
}
  
   
  
