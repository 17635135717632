
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.MuiButtonBase-root{
  font-size: large;
}

#validation-outlined-input {
  font-family: "Tajawal", sans-serif;
  color: #191970;
  font-size:14px;
}

#validation-outlined-input-label {
  font-family: "Tajawal", sans-serif;
  color: #191970;
  font-size:12px;
}

#validation-outlined-input-div {
  color: #191970;
border-width: 5px;
}



#validation-outlined-input .MuiOutlinedInput-notchedOutline{
  font-family: "Tajawal", sans-serif;
  color: #191970;
  font-size:16px;
  border-width: 5px;
}

#combo-box-demo-label{
  font-family: "Tajawal", sans-serif;
  color: #b31d3e;
  font-size:16px;
  font-weight: 700;
}
#TextFieldsearch{
  font-family: "Tajawal", sans-serif;
  color: #191970;
  font-size:16px;
}
#TextFieldsearch-label{
  font-family: "Tajawal", sans-serif;
  color: #b31d3e;
  font-size:16px;
  font-weight: 700;
}
 
#footer {
   
  bottom: 0;
  width: 100%;
}

.MuiInputBase-root{
  direction: rtl;
  text-align: center;
}
#formatted-text-mask-input{
  text-align: right;
    direction: ltr;
}