/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'SEGA LOGO FONT';
font-style: normal;
font-weight: normal;
src: local('SEGA LOGO FONT'), url('SEGA.woff') format('woff');
}

h1 {
  font-family: 'SEGA LOGO FONT';
  font-weight: 200;
  color: #0ccac4;
}
#HideBld{ display: none;}
.pagination{
  direction: ltr;
}

.selectsp{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
 
}
.selectbld{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
 
}
.selectwly{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
 
}

.input-lg{
  font-size: large;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 5px;
}
#applink{
  text-decoration: none;
}

.wrapapplink{
  margin: 10px;
}
html,
body {
    height: 100%;
}

    .container_loading {
      height: 100vh;
      display: flex;
    justify-content: center;
    align-items: center;
  }
  .txtspin {
  font-weight: bold;
  margin-top: 5px;
    
}
.spinsize{
  width: 3rem;
  height: 3rem;
}

   
  